import React from 'react';
import './PictureGrid.css';

const PictureGrid = () => {
    return (
        <section className='pictures-grid-section'>
            <div className='pictures-grid'>
                <img src="https://storage.googleapis.com/about-video/images/boxpic2.jpg" alt="Box 2" />
                <img src="https://storage.googleapis.com/about-video/images/boxpic7.jpg" alt="Box 7" />
                <img src="https://storage.googleapis.com/about-video/images/boxpic3.jpg" alt="Box 3" />
                <img src="https://storage.googleapis.com/about-video/images/boxpic4.jpg" alt="Box 4" />
                <img src="https://storage.googleapis.com/about-video/images/boxpic1.jpg" alt="Box 1" />
                <img src="https://storage.googleapis.com/about-video/images/boxpic5.jpg" alt="Box 5" />
                <img src="https://storage.googleapis.com/about-video/images/boxpic6.jpg" alt="Box 6" />
            </div>
        </section>
    );
};

export default PictureGrid;