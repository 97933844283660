import React from 'react';
import './HowItWorks.css'; // Optional CSS file for styling
import DeliveryCheck from './DeliveryCheck';

const DeliveriesPage = () => {
    return (
    <>
        <div className="deliveries-container">
            <section className='top-section'>
                <h2>Fuel Your Family For Greatness</h2>
                <a href="/checkout"><button className='button-83' role='button'>Start your farm to table journey here</button></a>
            </section>

            <section className="deliveries-steps">
            <div className='card-container'>
                <div className="card">
                    <img src="https://storage.googleapis.com/about-video/images/subscription-image.jpg" alt="Choose Subscription" />
                    <h2>Step 1: Choose Your Subscription</h2>
                    <p>Select a weekly, biweekly, or monthly subscription plan based on your needs.</p>
                </div>
            </div>

            <div className='card-container'>
                <div className="card">
                    <img src="https://storage.googleapis.com/about-video/images/produce-image.jpg" alt="Pick Produce" />
                    <h2>Step 2: Customize your Box</h2>
                    <p>We pride ourselves on our ability to craft your yummy produce box. Also you can send us feedback on your preferences.</p>
                </div>
            </div>

            <div className='card-container'>
                <div className="card">
                    <img src="https://storage.googleapis.com/about-video/images/source-produce.jpg" alt="Source Fresh Produce" />
                    <h2>Step 3: We Source Fresh Produce</h2>
                    <p>We handpick produce from local farms, ensuring it’s fresh and sustainably grown.</p>
                </div>
            </div>
    
            <div className='card-container'>
                <div className="card">
                    <img src="https://storage.googleapis.com/about-video/images/delivery-image.jpg" alt="Delivery" />
                    <h2>Step 4: Delivered to Your Doorstep</h2>
                    <p>Our team delivers your box directly to your home at a convenient time.</p>
                </div>
            </div>
    
            <div className='card-container'>
                <div className="card">
                    <img src="https://storage.googleapis.com/about-video/images/enjoy-image.jpg" alt="Enjoy and Reorder" />
                    <h2>Step 5: Enjoy</h2>
                    <p>Enjoy your fresh produce. Share your creations with #vitaverde</p>
                </div>
            </div>

            </section>
        </div>
        <section className='cta-container'>
            <a href="/checkout"><button className='button-83' role='button'>Start your farm to table journey here</button></a>
        </section>
    </>
    );
  };
  
  export default DeliveriesPage;

