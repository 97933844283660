import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';  // Import your CSS file

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // First, log in to get the JWT token
      const loginResponse = await axios.post('/api/auth/token/', {
        username,
        password,
      });
      const { access, refresh } = loginResponse.data;

      // Store tokens in localStorage (or session storage)
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);

      // Fetch the user information, including superuser status
      const userResponse = await axios.get('/api/auth/user/', {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      const { is_superuser } = userResponse.data;

      setSuccess('Login successful!');

      // Redirect based on superuser status
      if (is_superuser) {
        navigate('/admin');  // Redirect to admin page if user is a superuser
      } else {
        navigate('/dashboard');  // Redirect to dashboard or another route
      }
    } catch (err) {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <h2>Login</h2>
        <img src={`${process.env.PUBLIC_URL}/boxpic2.jpg`} alt="Box 2" />
        <img src={`${process.env.PUBLIC_URL}/Vita-Verde-Logo.png`} alt="Logo" className="logo" />
      </div>
      <div className="vertical-line"></div>
      <div className="login-right">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
          <button type="submit" className="submit-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;