import React, { useRef, useState, useEffect } from "react";
import { motion, useInView } from 'framer-motion';
import './AboutUs.css';

const AboutUs = () => {
    const ref = useRef(null);
    const inView = useInView(ref, { triggerOnce: true, threshold: 0.2 });
    const [isMobile, setIsMobile] = useState(false);

    // Check if the screen width is mobile size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Call the function on mount to set the initial value
        window.addEventListener('resize', handleResize); // Add listener to handle resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on unmount
        };
    }, []);

    return (
        <section className="about-us">
            <div className="about-content">
                <div className="about-text">
                    <h2>About Us</h2>

                    {isMobile ? (
                        <>
                            <p>
                                At <strong>Vita Verde</strong>, we understand that life is busy. 
                            </p>
                            <p>
                                Finding <u>Safe & Sustainable</u> produce for your household is almost impossible.
                            </p>
                            <p>
                                <strong>We are your personal connection to <u>the Finest Growers in California.</u></strong>
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                At <strong>Vita Verde</strong>, we believe in the power of fresh produce to nourish
                                both body and soul. Located in the heart of California, we are committed to sustainable
                                farming and healthy living for over a decade.
                            </p>
                            <p>
                                We take pride in delivering farm-fresh vegetables, fruits, and herbs directly to your home.
                                <strong>Our mission is to connect our community with nature's best, fostering a healthier lifestyle
                                while supporting sustainable agriculture.</strong>
                            </p>
                            <p>
                                Whether you're looking for a weekly supply of fresh produce or just want to try our seasonal
                                selections, Vita Verde is here to bring the best of the farm to your table.
                            </p>
                        </>
                    )}
                </div>

                <motion.div
                    ref={ref}
                    className="about-image"
                    initial={{ opacity: 0, x: 300}}
                    animate={inView ? { opacity: 1, x: 0} : {}}
                    transition={{ duration: 1.5, ease: "easeOut"}}
                >
                    <img src={`${process.env.PUBLIC_URL}/box.jpg`} alt="About Us" />
                </motion.div>
            </div>
        </section>
    );
};

export default AboutUs;