import React from 'react';
import './CustomizeDelivery.css'; // Optional CSS for styling
import { Carousel } from 'react-responsive-carousel'; // Install a carousel package like 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const reviews = [
  { name: "John Doe", review: "I have tried a few of the box produce, but by far this is my favorite. Excellent quality and excellent service. Michael is awesome! Keep up the good work guys.", img: "user1.jpg" },
  { name: "Jane Smith", review: "This service has been fantastic I get a thoughtfully curated box of organic fruits and veggies from local farms brought to my front door every Thursday night. We have massively increased the family veggie and fruit intake as a result. The total cost has been about 2 dollars per pound of veggies and fruit. I feel great and LOVE Vita Verde! Eat the rainbow!!", img: "user2.jpg" },
  { name: "Emily Johnson", review: "I highly recommend this farm box delivery service. These young men are true entrepreneurs and take pride in selecting and distributing the best local fruits and vegetables straight to you! Cancel your other subscriptions and support local!", img: "user3.jpg" }
];

const CustomizeDeliveryPage = () => {
  return (
    <div className="customize-delivery-container">
      {/* Introduction Section */}
      <header className="customize-header">
        <h1>Customize Your Delivery</h1>
        <p>
          At Vita Verde, we believe every household is unique. Our customizable delivery options allow you to select produce that fits your taste and lifestyle. Whether you're looking for seasonal favorites or sticking to a strict diet, our dashboard gives you full control over your weekly, biweekly, or monthly box.
        </p>
      </header>

      {/* How to Customize Section */}
      <section className="how-to-customize">
        <h2>How You Can Customize</h2>
        <p>
          Using our dashboard, you can easily:
        </p>
        <ul>
          <li>Select your delivery frequency (Weekly, Biweekly, Monthly)</li>
          <li>Choose the specific types of produce you want</li>
          <li>Set your preferred delivery day</li>
          <li>Add special instructions (e.g., "No carrots, extra apples")</li>
        </ul>
        <p>
          Customization gives you the power to receive exactly what you need, no more, no less!
        </p>
      </section>

      {/* Why Customization Section */}
      <section className="why-customize">
        <h2>Why We Believe in Customization</h2>
        <p>
          We understand that not everyone’s needs are the same. Some weeks you may need more greens, while other times you might want to try seasonal fruits. Customization helps reduce waste and ensures you're getting the freshest, most suitable produce for you and your family.
        </p>
      </section>

      {/* User Reviews Carousel Section */}
      <section className="user-reviews">
        <h2>What Our Users Are Saying</h2>
        <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
          {reviews.map((user, index) => (
            <div key={index} className="user-review">
              {/* <img src={user.img} alt={`${user.name}'s picture`} /> */}
              <h3>{user.name}</h3>
              <p>"{user.review}"</p>
            </div>
          ))}
        </Carousel>
      </section>
    </div>
  );
};

export default CustomizeDeliveryPage;