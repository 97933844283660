import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./HomePage";
import NavBar from "./NavBar";
import SubscriptionProducts from "./SubscriptionProducts";
import CreateAccount from "./CreateAccount";
import Login from "./Login";
import UserPage from "./UserPage";
import DeliveriesPage from "./HowItWorks";
import CustomizeDeliveryPage from "./CustomizeDelivery";
import AdminPage from "./AdminPage";
import CheckoutPage from "./CheckoutPage";
import ScrollToTop from "./ScrollToTop";

// Separate component for handling Navbar visibility
function MainApp() {
  const location = useLocation();

  // Define the path where to hide the Navbar
  const hideNavbarRoutes = ['/create-account', '/login'];

  // Determine whether to hide the Navbar
  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);

  return (
    <div>
      {/* Conditionally render Navbar based on the route */}
      {!shouldHideNavbar && <NavBar />}
      <ScrollToTop />
      <Routes>
        <Route path="/about-us" exact element={<HomePage />} />
        <Route path="/subscriptions" element={<SubscriptionProducts />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/dashboard" element={<UserPage/>} />
        <Route path="/" element={<DeliveriesPage />} />
        <Route path="/customize" element={<CustomizeDeliveryPage />} />
        <Route path="/admin" element={<AdminPage />} />
      </Routes>
    </div>
  );
}

// The Router is the top level, wrapping MainApp
function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

export default App;