import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import PictureGrid from './PictureGrid';
import './SubscriptionProducts.css';

const SubscriptionProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    const fetchSubscriptionProducts = async () => {
      try {
        const response = await fetch('http://localhost:8000/shopify/subscriptions/');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data && Array.isArray(data.products)) {
          setProducts(data.products);
        } else {
          throw new Error('Received invalid data structure from server');
        }
      } catch (e) {
        console.error("Error fetching subscription products:", e);
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionProducts();
  }, []);

  const handleBuy = (checkoutUrl) => {
    window.location.href = checkoutUrl;
  };

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const toggleDescription = (productId) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [productId]: !prev[productId]
    }));
  };

  const truncateDescription = (description, maxLength = 100) => {
    const sanitizedDesc = sanitizeHTML(description || 'No description available.');
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedDesc;
    const textContent = tempDiv.textContent || tempDiv.innerText;
    
    if (textContent.length <= maxLength) return sanitizedDesc;
    return textContent.substr(0, maxLength) + '...';
  };

  if (loading) return <div className="loading">Loading subscription products...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <>
    <div className='subscription-container'>
      <div className="subscription-products">
        <h2 className="title">Our Exclusive Deliveries</h2>
        {products.length === 0 ? (
          <p className="no-products">No subscription products found.</p>
        ) : (
          <div className="products-grid">
            {products.map((product) => (
              <div key={product.id} className="product-card">
                {product.image && (
                  <img src={product.image} alt={product.title} className="product-image" />
                )}
                <div className="product-info">
                  <h3 className="product-title">{product.title}</h3>
                  <div className="product-description">
                    {expandedDescriptions[product.id] 
                      ? <div dangerouslySetInnerHTML={{__html: sanitizeHTML(product.description || 'No description available.')}} />
                      : truncateDescription(product.description)}
                  </div>
                  <button 
                    className="see-more-button"
                    onClick={() => toggleDescription(product.id)}
                    >
                    {expandedDescriptions[product.id] ? 'See Less' : 'See More'}
                  </button>
                  <div className="product-footer">
                    <p className="product-price">${product.price}</p>
                    <button
                      onClick={() => handleBuy(product.checkout_url)}
                      className="buy-button"
                      >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    <div>
      <PictureGrid />
    </div>
    </div>
  </>
  );
};

export default SubscriptionProducts;