import React, { useState } from "react";
import ReviewCarousel from "./ReviewCarousel";
import ProduceCarousel from "./ProduceCarousel";
import './CheckoutPage.css';
import WeeklyProduct from "./WeeklyProduct";
import BiWeeklyProduct from "./BiWeeklyProduct";
import MonthlyProduct from "./MonthlyProduct";
import PictureGrid from "./PictureGrid";

const ProductCycle = () => {
    const [currentProduct, setCurrentProduct] = useState('weekly');
    const [isLoading, setIsLoading] = useState(false);

    const cycleProduct = () => {
        setIsLoading(true);

        setTimeout(() => {
            setCurrentProduct(current => {
                switch(current) {
                    case 'weekly':
                        return 'biweekly';
                    case 'biweekly':
                        return 'monthly';
                    case 'monthly':
                        return 'weekly';
                    default:
                        return 'weekly';
                }
            });
            setIsLoading(false);
        }, 1000); // 1 second delay
    };

    const renderProduct = () => {
        if (isLoading) {
            return (
                <div className="product-loading">
                    <div className="spinner"></div>
                    <p>Loading...</p>
                </div>
            );
        }
        switch(currentProduct) {
            case 'weekly':
                return <WeeklyProduct />;
            case 'biweekly':
                return <BiWeeklyProduct />;
            case 'monthly':
                return <MonthlyProduct />;
            default:
                return <WeeklyProduct />;
        }
    };

    return (
        <div>
            <div className="product-container">
                {renderProduct()}
            </div>
            <div className="button-container">
                <button
                    onClick={cycleProduct}
                    className="button-83 cycle-button"
                >
                    Does {currentProduct} not make sense for you?
                </button>
            </div>
        </div>
    );
};

const CheckoutPage = () => {
    const produceItems = [
        {
            image: 'images/boxpic1.jpg',
            title: 'Fresh Vegetables',
            description: 'A variety of locally sourced vegetables',
          },
          {
            image: 'images/boxpic2.jpg',
            title: 'Organic Fruits',
            description: 'Delicious, pesticide-free fruits',
          },
    ];

    return(
        <>
        <div className="main-page">
            <section className="review_section">
                <ReviewCarousel />
            </section>
            <section className="content-section">
                <div className="content-section__right">
                    <ProductCycle />
                </div>
                <div className="content-section__left">
                    <PictureGrid />
                </div>
            </section>
        </div>
        </>
    );
};

export default CheckoutPage;