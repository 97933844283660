import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './DeliveryInfo.css'; // Import your CSS
import { useActionData } from 'react-router-dom';

const localizer = momentLocalizer(moment);

const DeliveryInfo = ({ customerId, customerEmail }) => {
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [error, setError] = useState('');
  const [events, setEvents] = useState([]);
  const [savings, setSavings] = useState(0);
  const [boxWants, setBoxWants] = useState('');
  const [boxDontWants, setBoxDontWants] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [cancelMessage, setCancelMessage] = useState('');
  const [reactivateMessage, setReactivateMessage] = useState('');
  const [pauseMessage, setPauseMessage] = useState('');
  const [resumeMessage, setResumeMessage] = useState('');
  const accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    const fetchDeliveryInfo = async () => {
      try {
        const response = await axios.get(`/api/customer/${customerId}/delivery/`);
        const data = response.data;

        // Calculate savings
        const deliveriesReceived = data.total_deliveries;
        const costWithService = deliveriesReceived * 50;
        const costWithoutService = deliveriesReceived * 90;
        const totalSavings = costWithoutService - costWithService;

        setSavings(totalSavings);
        setDeliveryInfo(data);

        setBoxWants(data.box_wants || '');
        setBoxDontWants(data.box_dont_wants || '');

        // Fix the date to avoid time zone issues
        const nextDeliveryDate = new Date(data.next_delivery_date);
        const correctedDeliveryDate = new Date(
          nextDeliveryDate.getUTCFullYear(),
          nextDeliveryDate.getUTCMonth(),
          nextDeliveryDate.getUTCDate()
        );

        // Create a calendar event for the next delivery date
        const nextDeliveryEvent = {
          title: `Next Delivery (${data.subscription_type})`,
          start: correctedDeliveryDate,
          end: correctedDeliveryDate,
          allDay: true,
        };

        setEvents([nextDeliveryEvent]);
      } catch (err) {
        console.error('Failed to fetch delivery info:', err);
        setError('Could not fetch delivery information.');
      }
    };

    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get(`/api/customer/subscription-status/${customerId}/`);
        setSubscriptionStatus(response.data.subscription_status);
      } catch (err) {
        console.error('Failed to fetch subscription status:', err);
        setError('Could not fetch subscription status.');
      }
    };

    fetchDeliveryInfo();
    fetchSubscriptionStatus();
  }, [customerId]);

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(`/api/customer/${customerId}/cancel/`, {}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.data.success) {
        setSubscriptionStatus('CANCELLED');
        setCancelMessage('Subscription successfully canceled.');
        window.location.reload();
      } else {
        setCancelMessage('Failed to cancel the subscription.');
      }
    } catch (error) {
      setCancelMessage('An error occurred while canceling the subscription');
    }
  };

  const handleReactivateSubscription = async () => {
    try {
      const response = await axios.post(`/api/customer/${customerId}/reactivate/`, {}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      
      if (response.data.success) {
        setSubscriptionStatus('ACTIVE');
        setReactivateMessage('Subscription successfully reactivated.');
        window.location.reload();
      } else {
        setReactivateMessage('Failed to reactivate the subscription.');
      }
    } catch (error) {
      setReactivateMessage('An error occurred while reactivating the subscription.');
    }
  };

  const handlePauseSubscription = async () => {
    try {
      const response = await axios.put(`/api/customer/${customerId}/pause-subscription/`, {}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.status === 200) {
        setSubscriptionStatus('PAUSED');
        setPauseMessage('Subscription paused successfully');
        window.location.reload();
      } else {
        setPauseMessage('Failed to pause the subscription');
      }
    } catch (err) {
      setPauseMessage('Error occurred while pausing the subscription');
    }
  };

  const handleResumeSubscription = async () => {
    try {
      const response = await axios.put(`/api/customer/${customerId}/resume-subscription/`, {}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.status === 200) {
        setSubscriptionStatus('ACTIVE');
        setResumeMessage('Subscription resumed successfully');
        window.location.reload();
      } else {
        setResumeMessage('Failed to resume the subscription');
      }
    } catch (err) {
      setResumeMessage('Error occurred while resuming the subscription');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem('access_token');

    try {
      const response = await axios.post(
        `/api/customer/${customerId}/update-delivery-preferences/`,
        {
          box_wants: boxWants,
          box_dont_wants: boxDontWants,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        }
      );

      if (response.status === 200) {
        setSubmitMessage('Preferences updated successfully!');
      } else {
        setSubmitMessage('Failed to update preferences.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="delivery-info-container">
      <h3 className="delivery-info-header">Upcoming Delivery</h3>
      {error && <p className="error-message">{error}</p>}
      {!error && (
        <>
          <div className="delivery-info-details">
            <p><strong>Order Date:</strong> {deliveryInfo.order_date}</p>
            <p><strong>Subscription Type:</strong> {deliveryInfo.subscription_type}</p>
          </div>
          <div className="delivery-info-details">
            <p><strong>Next Delivery Date:</strong> {deliveryInfo.next_delivery_date}</p>
            <p><strong>Total Deliveries Received:</strong> {deliveryInfo.total_deliveries}</p>
          </div>
          <div className="delivery-info-details">
            <p><strong>Last Delivery Date:</strong> {deliveryInfo.last_delivery_date ? deliveryInfo.last_delivery_date : 'N/A'}</p>
          </div>
          <div className="delivery-info-details">
            <p><strong>Subscription Status:</strong> {subscriptionStatus}</p>
          </div>
          <div className="delivery-info-highlight">
            <p>Money Saved Using Our Service: ${savings}</p>
          </div>

          {/* Form for submitting preferences */}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="boxWants">What would you like in your box?</label>
              <textarea
                id="boxWants"
                value={boxWants}
                onChange={(e) => setBoxWants(e.target.value)}
                placeholder={deliveryInfo.box_wants || 'You can request up to 8 produce items'}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="boxDontWants">What wouldn't you like in your box?</label>
              <textarea
                id="boxDontWants"
                value={boxDontWants}
                onChange={(e) => setBoxDontWants(e.target.value)}
                placeholder={deliveryInfo.box_dont_wants || "Enter items you don't want in your box"}
                className="form-control"
              />
            </div>

            <button type="submit" className="btn btn-primary">Submit Preferences</button>
            {submitMessage && <p className="submit-message">{submitMessage}</p>}
          </form>

          <div className='subscription-buttons'>
            <div className='cancel-buttons'>
              {/* Display Cancel or Reactivate button based on status */}
              {subscriptionStatus === 'ACTIVE' ? (
                <>
                  <button onClick={handleCancelSubscription} className='btn btn-danger'>
                    Cancel Subscription
                  </button>
                  {cancelMessage && <p>{cancelMessage}</p>}
                </>
              ) : subscriptionStatus === 'PAUSED' ? (
                <>
                  <button onClick={handleCancelSubscription} className='btn btn-danger'>
                    Cancel Subscription
                  </button>
                  {cancelMessage && <p>{cancelMessage}</p>}
                </>
              ) : subscriptionStatus === 'CANCELLED' ? (
                <>
                  <button onClick={handleReactivateSubscription} className='btn btn-danger'>
                    Reactivate Subscription
                  </button>
                  {reactivateMessage && <p>{reactivateMessage}</p>}
                </>
              ) : (
                <p>Subscription status unknown or not applicable.</p>
              )}
            </div>
            
            <div className='pause-buttons'>
              {/* Display a Pause Subscription button if the subscription is active */}
              {subscriptionStatus === 'ACTIVE' ? (
                <>
                  <button onClick={handlePauseSubscription} className='btn btn-danger'>
                    Pause Subscription
                  </button>
                  {pauseMessage && <p>{pauseMessage}</p>}
                </>
              ) : subscriptionStatus === 'PAUSED' ? (
                <>
                  <button onClick={handleResumeSubscription} className='btn btn-danger'>
                    Resume Subscription
                  </button>
                  {resumeMessage && <p>{resumeMessage}</p>}
                </>
              ) : (
                <p>Subscription status unknown or not applicable.</p>
              )}
            </div>
          </div>
          
          <div className="calendar-container">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveryInfo;