import React, { useState, useRef } from "react";
import "./ReviewCarousel.css";

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} className={star <= rating ? "star filled" : "star"}>
          ★
        </span>
      ))}
    </div>
  );
};

const ReviewCard = ({ user }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const previewText = user.fullReview.slice(0, 100) + (user.fullReview.length > 100 ? "..." : "");

  const toggleExpand = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="review-card" style={{ backgroundImage: `url(${user.picture})` }}>
      <div className="review-card__content">
        <h3 className="review-card__name">{user.name}</h3>
        <StarRating rating={user.rating} />
        <p className="review-card__text">
          {isExpanded ? user.fullReview : previewText}
        </p>
        {!isExpanded && user.fullReview.length > 100 && (
          <a href="#" className="review-card__read-more" onClick={toggleExpand}>
            ▼ Read More
          </a>
        )}
        {isExpanded && (
          <a href="#" className="review-card__read-less" onClick={toggleExpand}>
            ▲ Read Less
          </a>
        )}
      </div>
    </div>
  );
};

const ReviewCarousel = () => {
  const carouselRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const users = [
    {
      name: "Russ Johnson",
      picture: `${process.env.PUBLIC_URL}/images/russ.png`,
      rating: 5,
      fullReview: "This service has been fantastic! A thoughtfully curated box of organic fruits and veggies from local farms, brought to my front door every Thursday night. We have massively increased the family veggie and fruit intake as a result. The total cost has been about 2 dollars per pound of veggies and fruit. I feel great and LOVE Vita Verde! Eat the rainbow!!"
    },
    {
      name: "Holly Rios",
      picture: `${process.env.PUBLIC_URL}/images/holly.png`,
      rating: 5,
      fullReview: "I've been using this service for 6 months now, and I'm incredibly impressed. The quality of the produce is consistently high, and I love trying new seasonal vegetables. It's made meal planning much more exciting!"
    },
    {
      name: "Chris Kirk",
      picture: `${process.env.PUBLIC_URL}/images/chris.png`,
      rating: 5,
      fullReview: "As someone who was skeptical about subscription produce boxes, I'm happy to say this service has won me over. The convenience is unmatched, and I appreciate the focus on local, sustainable farming."
    },
    {
      name: "Margaret Gossett",
      picture: `${process.env.PUBLIC_URL}/images/margaret.png`,
      rating: 5,
      fullReview: "I've been using Vita Verde for about a year. We are consistently impressed with both the quantity and quality of produce we receive. The carrots and celery are a marvel (I never noticed anything special about these staples when I bought them at the market).The seasonal veggies and fruit are superb! And we love the attentive service and getting our order delivered. Keep up the good work!"
    },
    {
      name: "Eli Helfman",
      picture: `${process.env.PUBLIC_URL}/images/eli.png`,
      rating: 5,
      fullReview: "These guys are great, we have loved the fruit and veggies they have provided, super fresh and delish offerings. Was really happy we decided to sign up for this service."
    }
    // Add more users/reviews as needed
  ];

  const scroll = (direction) => {
    const container = carouselRef.current;
    const scrollAmount = direction === 'left' ? -container.offsetWidth : container.offsetWidth;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    
    // Update scroll position after scrolling
    setTimeout(() => {
      setScrollPosition(container.scrollLeft);
    }, 500);
  };

  const showLeftArrow = scrollPosition > 0;
  const showRightArrow = carouselRef.current && 
    scrollPosition < carouselRef.current.scrollWidth - carouselRef.current.clientWidth;

  return (
    <div className="review-carousel-container">
      <h2>Why Vita Verde?</h2>
      <div className="review-carousel" ref={carouselRef}>
        {users.map((user, index) => (
          <ReviewCard key={index} user={user} />
        ))}
      </div>
    </div>
  );
};

export default ReviewCarousel;