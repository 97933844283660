import React from 'react';
import './HomePage.css';
import AboutUs from './AboutUs';
import Value from './Value';
import DeliveryCheck from './DeliveryCheck';

const HomePage = () => {

  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero">
        <video autoPlay muted loop className='hero-video'>
            <source src="https://storage.googleapis.com/about-video/Vita-Verde-Landing-Page.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      </section>

      <section className='SectionContainer'>
        <div className='centered-container'>
            <h1>
                WELCOME TO VITA VERDE!<br />
                CALIFORNIA'S FARM FRESH<br /> 
                PRODUCE DELIVERY SERVICE!
            </h1>
        </div>
        <div className='OrderButton'>
            <a href="/checkout" className='btn btn-primary'>ORDER NOW</a>
        </div>
      </section>

      
      <>
        <div className='about-value-section'>
          <AboutUs />
          <Value />
        </div>
        <DeliveryCheck />
      </>

      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; 2024 Vita Verde. All rights reserved.</p>
        <div className="social-media">
          <a href="#">Facebook</a>
          <a href="#">Instagram</a>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;