import React, { useState, useEffect } from 'react';

const BiWeeklyProduct = () => {
  const [products, setProducts] = useState([]);
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('/shopify/subscriptions/'); // Adjust this URL to match your Django URL configuration
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data.products);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const biWeeklyProduct = products.find(product => 
    product.checkout_url && product.checkout_url.includes('46665079488762')
  );

  const truncateDescription = (description) => {
    const stripped = description.replace(/<[^>]*>/g, '');
    return stripped.length > 100 ? stripped.slice(0, 100) + '...' : stripped;
  };

  const toggleDescription = () => {
    setExpandedDescription(!expandedDescription);
  };

  // Simple sanitization function
  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!biWeeklyProduct) {
    return (
      <div className='subscription-container'>
        <div className="subscription-products">
          <h2 className="title">Our Bi-Weekly Delivery</h2>
          <p className="no-products">No weekly product found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className='subscription-container'>
      <div className="subscription-products">
        <h2 className="title">Our Bi-Weekly Delivery</h2>
        <div className="product-card">
          {biWeeklyProduct.image && (
            <img src={biWeeklyProduct.image} alt={biWeeklyProduct.title} className="product-image" />
          )}
          <div className="product-info">
            <h3 className="product-title">{biWeeklyProduct.title}</h3>
            <div className="product-description">
              {expandedDescription 
                ? sanitizeHTML(biWeeklyProduct.description || 'No description available.')
                : truncateDescription(biWeeklyProduct.description)}
            </div>
            <button 
              className="see-more-button"
              onClick={toggleDescription}
            >
              {expandedDescription ? 'See Less' : 'See More'}
            </button>
            <div className="product-footer">
              <p className="product-price">${biWeeklyProduct.price}/month</p>
              <a
                href={biWeeklyProduct.checkout_url}
                className="buy-button"
                rel="noopener noreferrer"
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiWeeklyProduct;