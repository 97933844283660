import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProduceCalculator.css';

const ProduceCalculator = ({ weeklyDeliveries }) => {
  const [produceItems, setProduceItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');

    axios
      .get('/api/customer/produce-items/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProduceItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching produce items:', error);
        setError('Failed to fetch produce items');
        setLoading(false);
      });
  }, []);

  // Handle selection of produce items
  const handleSelectionChange = (e, produceId) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedItems([...selectedItems, produceId]);
    } else {
      setSelectedItems(selectedItems.filter(item => item !== produceId));
    }
  };

  // Calculate the total order for the selected items
  const handleCalculate = () => {
    const token = localStorage.getItem('access_token');
    axios.post(
        '/api/customer/calculate-produce/',
        {
          deliveries_count: weeklyDeliveries,
          produce_ids: selectedItems,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTotalOrders(response.data);
      })
      .catch((error) => {
        console.error('Error calculating produce:', error);
        setError('Failed to calculate produce orders');
      });
    };

    if (loading) {
      return <p>Loading produce items...</p>
    }

    if (error) {
      return <p>{error}</p>
    }

  return (
    <div className="produce-calculator-container">
      <h1>Produce Calculator</h1>
      <p>Select the produce items you want to include:</p>

      <div className="produce-selection">
        {produceItems.map(produce => (
          <div key={produce.id} className="produce-item">
            <input
              type="checkbox"
              id={`produce-${produce.id}`}
              onChange={e => handleSelectionChange(e, produce.id)}
            />
            <label htmlFor={`produce-${produce.id}`}>{produce.name}</label>
          </div>
        ))}
      </div>

      <button onClick={handleCalculate} className="calculate-btn">
        Recalculate Orders
      </button>

      {totalOrders.length > 0 && (
        <div className="order-summary">
          <h2>Total Orders Summary</h2>
          <ul>
            {totalOrders.map(order => (
              <li className='order-item' key={order.name}>
                {order.name}:<br /> <strong>{order.requiredOrders} order(s)</strong> - {order.totalQuantityOrdered} units total
                <br />
                <br />
                {order.remainder > 0 && (
                  <span>{order.remainder} units will be leftover after fulfilling deliveries.</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProduceCalculator;