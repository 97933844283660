import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import './CreateAccount.css';

const CreateAccount = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    // Function to extract email from URL query params
    const getEmailFromUrl = () => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('email') || '';
    };

    useEffect(() => {
        const emailFromUrl = getEmailFromUrl();
        setEmail(emailFromUrl);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post('/api/auth/register/', {
                email,
                password
            });
            setSuccess('Account created successfully!');
            navigate('/login');
        } catch (err) {
            if (err.response && err.response_data) {
                console.error('Backend error:', err.response_data);
                setError(`Failed to create account. ${err.response.data.detail || 'Unknown error'}`);
            } else {
                setError('Failed to create account. Please try again.');
            }
        }
    };

    return (
        <div className="create-account-container">
            <div className="create-account-left">
                <h2>Create Account</h2>
                <img src={`${process.env.PUBLIC_URL}/Vita-Verde-Logo.png`} alt="Logo" className="logo" />
            </div>
            <div className="vertical-line"></div>
            <div className="create-account-right">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email (Username)</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input 
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                    <button type="submit">Create Account</button>
                </form>
            </div>
        </div>
    );
};

export default CreateAccount;