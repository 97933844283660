import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserPage.css';  // Import CSS for styling
import DeliveryInfo from './DeliveryInfo';

const UserPage = () => {
  const [userData, setUserData] = useState(null);  // Start with null, not an empty object
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch user data from the API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');  // Retrieve the token

        if (!accessToken) {
          // If no access token is found, redirect to login
          navigate('/login');
          return;
        }

        const response = await axios.get('/api/auth/user/', {  // Fetch user info
          headers: {
            Authorization: `Bearer ${accessToken}`  // Attach the access token
          }
        });

        setUserData(response.data);  // Store user data
      } catch (err) {
        setError('Unable to fetch user data. Redirecting to login...');
        setTimeout(() => {
          navigate('/login');  // Redirect to login if user data can't be fetched
        }, 2000);  // Optional delay for displaying error message
      }
    };

    fetchUserData();
  }, [navigate])

  // Logout function to clear the tokens and redirect to the login page
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    navigate('/login');
  }

  if (error) {
    return <p className="error">{error}</p>;
  }

  if (!userData) {
    return <p>Loading...</p>;  // Show loading if user data hasn't loaded yet
  }


  return (
    <>
    <div className='user-page'>
      <div className="user-page-container">
        <h2>Your Profile</h2>
        <div className="user-info">
          <p><strong>Name:</strong> {userData.first_name} {userData.last_name}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Phone:</strong> {userData.phone}</p>
        </div>
      </div>
      {/* Ensure customerId is only passed when userData is fully loaded */}
      {userData.id && <DeliveryInfo customerId={userData.customer_id} customerEmail={userData.email} />}

      {/* Logout Button */}
      <button className='logout-button' onClick={handleLogout}>Logout</button>
    </div>
    </>
  );
};

export default UserPage;

