import React, { useRef, useState, useEffect } from "react";
import { motion, useInView } from 'framer-motion';
import './Value.css';

const AboutUs = () => {
    const ref = useRef(null);
    const inView = useInView(ref, { triggerOnce: true, threshold: 0.2 });
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Call the function on mount to set the initial value
        window.addEventListener('resize', handleResize); // Add listener to handle resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on unmount
        };
    }, []);

    return (
        <section className="value-section">
            <div className="value-content">
                <div className="value-text">
                    <h2>Value We Provide</h2>
                    
                    {isMobile ? (
                        <>
                            <p>
                                Other services don't offer the flexibility to customize your farm-fresh delivery to fit your household's unique needs.<br />
                                 
                            </p>
                            <p>
                                We make sure that your customizable delivery brings exactly what your household needs.
                            </p>
                            <p>    
                                <strong>Allowing you to fuel your family for Greatness.</strong>
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                We know how busy life can get. That's why we offer flexible delivery options,
                                bringing farm-fresh products to your home on a weekly, bi-weekly, or monthly
                                basis. Wheter you're a seasoned chef or a family looking for healthy meals, we
                                make farm-to-table easy and convenient.
                            </p>
                            <p>
                                We understand that every household has different tastes and needs. Our subscription
                                boxes are customizable, allowing you to choose the fruits and vegetables you love the
                                most. Plus, we regularly introduce new seasonal selections, so you can experience a variety
                                of fresh flavors all year round.
                            </p>
                            <p>
                                By choosing <strong>Vita Verde</strong>, you're not just buying produce; you're
                                supporting small, local farms. We work closely with farmers in our community, ensuring
                                fair trade practices that allow them to grow their businesses sustainably while
                                providing you with the highest quality produce.
                            </p>
                        </>
                    )}
                </div>

                <motion.div
                    ref={ref} 
                    className="value-image"
                    initial={{ opacity: 0, x: -300}}
                    animate={inView ? { opacity: 1, x: 0} : {}}
                    transition={{ duration: 1.5, ease: "easeOut"}}
                >
                    <img src={`${process.env.PUBLIC_URL}/box1.jpg`} alt="About Us" />
                </motion.div>
            </div>
        </section>
    );
};

export default AboutUs;