import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPage.css';  // Import the CSS file for styling
import ProduceCalculator from './ProduceCalculator';

const AdminPage = () => {
  const [weeklyDeliveries, setWeeklyDeliveries] = useState(0);
  const [totalPounds, setTotalPounds] = useState(0);
  const [requiredDrivers, setRequiredDrivers] = useState(0);
  const [driversPay, setDriversPay] = useState(0);
  const [warehouseWorkers, setWarehouseWorkers] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [produceCost, setProduceCost] = useState(0);
  const [boxPreferences, setBoxPreferences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  {/* Messaging consts */}
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);

  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (token) {
      axios.get('/api/customer/weekly-deliveries/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        const weeklyDeliveriesCount = response.data.weekly_deliveries;
        setWeeklyDeliveries(weeklyDeliveriesCount);
        setTotalPounds(weeklyDeliveriesCount * 15);

        const driversNeeded = Math.ceil(weeklyDeliveriesCount / 40);
        setRequiredDrivers(driversNeeded);

        const pay = Math.ceil(driversNeeded * 200);
        setDriversPay(pay);

        const workers = Math.ceil(weeklyDeliveriesCount / 100);
        setWarehouseWorkers(workers);

        const money = Math.ceil(weeklyDeliveriesCount * 50);
        setRevenue(money);

        const produce = Math.ceil(weeklyDeliveriesCount * 14);
        setProduceCost(produce);

        const money_kept = Math.ceil(money - produce);
        setProfit(money_kept);


        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching weekly deliveries:', error);
        setError('Failed to fetch data');
        setLoading(false);
      });
    
      axios.get('/api/customer/weekly-deliveries-with-wants/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        setBoxPreferences(response.data);
      })
      .catch(error => {
        console.error('Error fetching box_wants:', error);
        setError('Failed to fetch box_wants');
      });
    } else {
      setError('User not authenticated');
      setLoading(false);
    }
  }, [token]);

  const handleSendDeliveries = async () => {
    try {
      const response = await axios.post('/api/customer/send-deliveries/', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Deliveries sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending deliveries:', error)
    }
  };

  const handleSendMessage = async () => {
    setIsSending(true);
    try {
      await axios.post('/api/customer/send-sms/', { message });
      setSendSuccess(true);
    } catch (error) {
      console.error('Failed to send message:', error);
      setSendSuccess(false);
    } finally {
      setIsSending(false);
    }
  };


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="admin-container">
      <aside className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li>Dashboard</li>
          <li>Deliveries</li>
          <li>Orders</li>
          <li>Users</li>
          <li>Settings</li>
        </ul>
      </aside>
      <main className="main-content">
        <header>
          <h1>Admin Dashboard</h1>
        </header>
        <section className="stats-container">
          <div className="card">
            <h2>Weekly Thursday Deliveries</h2>
            <p>{weeklyDeliveries} deliveries are scheduled for this Thursday.</p>
          </div>
          <div className="card">
            <h2>Total Pounds of Produce</h2>
            <p>{totalPounds} lbs</p>
          </div>
        </section>
        <section className="stats-container">
          <div className="card">
            <h2>Delivery Drivers Needed</h2>
            <p>
              {requiredDrivers} delivery driver(s) for this Thursday.</p>
          </div>
          <div className="card">
            <h2>Cost of Driver(s)</h2>
            <p>${driversPay}</p>
          </div>
          <div className="card">
            <h2>Warehouse Workers Needed</h2>
            <p>You need {warehouseWorkers} to put together {weeklyDeliveries} boxes.</p>
          </div>
        </section>
        <section className='stats-container'>
          <div className='card'>
            <h2>Revenue this Upcoming Delivery Day</h2>
            <p>${revenue}</p>
          </div>
          <div className='card'>
            <h2>Profit without including Drivers Pay</h2>
            <p>${profit}</p>
          </div>
          <div className='card'>
            <h2>Cost of Produce</h2>
            <p>${produceCost}</p>
          </div>
        </section>
        <section className='message-container'>
          <h2>Send Text Message to this Weeks Customers</h2>
          <textarea
            placeholder='Enter message to send'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={handleSendMessage} disabled={isSending}>
            {isSending ? 'Sending...' : 'Send Message'}
          </button>
          {sendSuccess && <p>Message sent successfully!</p>}
        </section>
        <section className='stats-container'>
          <div className='card'>
            <h2>Box Preferences for this Week's Deliveries</h2>
            {boxPreferences.length > 0 ? (
              <ul>
                {boxPreferences.map(delivery => (
                  <li key={delivery.id}>
                    <strong>{delivery.customer_name}:</strong>
                    <p><strong>Address:</strong> {delivery.customer_address1}, {delivery.customer_city}, {delivery.customer_zip}</p>
                    <p><strong>Wants:</strong> {delivery.box_wants}</p> 
                    <p><strong>Don't Wants:</strong> {delivery.box_dont_wants}</p> 
                  </li>
                ))}
                <button className='route-button' onClick={handleSendDeliveries}>Send Deliveries to Roadwarrior</button>
              </ul>
            ) : (
              <p>No box preferences specified for this week's deliveries.</p>
            )}
          </div>
        </section>
        <ProduceCalculator weeklyDeliveries={weeklyDeliveries}/>
      </main>
    </div>
  );
};

export default AdminPage;
